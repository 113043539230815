exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-posts-20220314-summary-of-my-crypto-investment-in-these-days-md": () => import("./../../../src/posts/20220314-summary-of-my-crypto-investment-in-these-days.md" /* webpackChunkName: "component---src-posts-20220314-summary-of-my-crypto-investment-in-these-days-md" */),
  "component---src-posts-20220316-zero-knowledge-proof-and-gossip-md": () => import("./../../../src/posts/20220316-zero-knowledge-proof-and-gossip.md" /* webpackChunkName: "component---src-posts-20220316-zero-knowledge-proof-and-gossip-md" */),
  "component---src-posts-20220324-a-smart-way-to-solve-leetcode-boats-to-save-people-in-rust-md": () => import("./../../../src/posts/20220324-a-smart-way-to-solve-leetcode-boats-to-save-people-in-rust.md" /* webpackChunkName: "component---src-posts-20220324-a-smart-way-to-solve-leetcode-boats-to-save-people-in-rust-md" */),
  "component---src-posts-20220325-copilot-is-so-great-md": () => import("./../../../src/posts/20220325-copilot-is-so-great.md" /* webpackChunkName: "component---src-posts-20220325-copilot-is-so-great-md" */),
  "component---src-posts-20220412-a-failure-of-manual-trading-md": () => import("./../../../src/posts/20220412-a-failure-of-manual-trading.md" /* webpackChunkName: "component---src-posts-20220412-a-failure-of-manual-trading-md" */)
}

